import React, { createContext, useContext, useState } from 'react';

import { MODALS } from '../constants';
import AdminClauseModal from '../containers/Admin/Clauses/modals/AdminClauseModal';
import AdminTagModal from '../containers/Admin/Tags/modals/AdminTagModal';
import AdminCreateTemplateModal from '../containers/Admin/Templates/modals/AdminCreateTemplateModal';

const modalsMap = new Map();
modalsMap.set(MODALS.ADMIN_CLAUSE, AdminClauseModal);
modalsMap.set(MODALS.ADMIN_CREATE_TEMPLATE, AdminCreateTemplateModal);
modalsMap.set(MODALS.ADMIN_TAG, AdminTagModal);

type ModalContextType = {
  modal: string | undefined;
  showModal: (name: React.SetStateAction<string>, nextProps: any) => void;
  closeModal: () => void;
  props: any;
};

const ModalContext = createContext({} as ModalContextType);

export const useModal = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }: { children: any }) => {
  const [modal, setVisibleModal] = useState('');
  const [props, setProps] = useState(null);

  const showModal = (name: React.SetStateAction<string>, nextProps: React.SetStateAction<null>) => {
    setVisibleModal(name);
    setProps(nextProps);
  };
  const closeModal = () => setVisibleModal('');

  return <ModalContext.Provider value={{ modal, showModal, closeModal, props }}>{children}</ModalContext.Provider>;
};

export const ModalSwitch = () => {
  const { modal, closeModal, props } = useModal();
  const Component = modalsMap.get(modal);

  if (!Component) {
    return null;
  }

  return <Component handleClose={closeModal} {...props} />;
};
