import React, { forwardRef } from 'react';

import clsx from 'clsx';

import './InputReadOnly.css';
import type { UIComponentSizes, UIComponentSize } from '../../@types/types';
import Icon from './Icon';

const sizes: UIComponentSizes = {
  small: 'h-10',
  normal: 'h-12',
};

interface InputReadonlyProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  placeholderText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  name?: string;
  errors?: string | string[];
  size?: UIComponentSize;
}

const Input = forwardRef<any, InputReadonlyProps>(function Input(
  { className, placeholderText, size = 'normal', isDisabled, isRequired, id, name, errors, ...props },
  ref
) {
  return (
    <div className={clsx('relative', sizes[size])}>
      <input
        className={clsx(
          'aui-input aui-input-readonly text-sm -mt-2 font-light w-full',
          errors && 'aui-input--errors border-red-300',
          sizes[size],
          className
        )}
        disabled={isDisabled}
        id={id || name}
        placeholder={placeholderText}
        readOnly
        ref={ref}
        required={isRequired}
        {...props}
      />
      <div className={clsx('flex w-10 z-10 items-center justify-center absolute read-only-icon right-0')}>
        <Icon name="lock" className="w-3 fill-current text-gray-800" />
      </div>
    </div>
  );
});

export default Input;
