import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';

import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import type { ClauseShort, Tag } from '../../../../../@types/types';
import Editor from '../../../../components/Editor';
import InputMultiSelect from '../../../../components/InputMultiSelect';
import InputSelect from '../../../../components/InputSelect';
import InputText from '../../../../components/InputText';
import Modal from '../../../../components/Modal';
import { createClauseInLibrary, updateClauseInLibrary } from '../../../../mutations';
import { clauseTypes } from '../../../ClauseLibrary/constants';

const AdminClauseModal = ({
  clause = {
    id: uuidv4(),
    content: '',
    type: '',
    description: '',
    created_at: '',
    tags: [],
  },
  handleClose,
  setShouldFetchData,
  isAdd,
  tagsLibraryData,
}: {
  clause: ClauseShort;
  handleClose: () => void;
  setShouldFetchData: Dispatch<SetStateAction<boolean>>;
  isAdd: boolean;
  tagsLibraryData: Tag[];
}) => {
  const [formValues, setFormValues] = useState(clause);
  const [editorContent, setEditorContent] = useState(formValues.content);
  const handleChangeClauseDescription = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, description: target.value });
  const handleChangeClauseType = (type: any) => setFormValues({ ...formValues, type });
  const handleChangeClauseTags = (tagsLabels: string[]) =>
    setFormValues({
      ...formValues,
      tags: tagsLibraryData.filter((tag: Tag) => tagsLabels.includes(tag.label)).map((tag: Tag) => tag.id),
    });
  const handleChangeClauseContent = (content: any) => setEditorContent(content);

  useEffect(() => {
    setFormValues({ ...formValues, content: editorContent });
  }, [editorContent]);

  const handleSubmitClause = async () => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (isAdd) {
        await createClauseInLibrary(formValues, queryParams.authToken as string);
      } else {
        await updateClauseInLibrary(formValues.id, formValues, queryParams.authToken as string);
      }
      setShouldFetchData(true);
    } catch (e) {
      console.log(e);
    }
  };

  const archiveOrRestoreClause = async () => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      await updateClauseInLibrary(
        clause.id,
        {
          deleted_at: formValues.deleted_at ? null : new Date().toISOString(),
        },
        queryParams.authToken as string
      );
      setShouldFetchData(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      open
      className="w-full lg:w-1/2 sm:w-5/6"
      onClose={handleClose}
      title={`${isAdd ? 'Create' : 'Edit'} clause`}
    >
      <InputText
        labelText="Clause name"
        isRequired
        value={formValues.description}
        onChange={handleChangeClauseDescription}
      />
      <InputSelect
        labelText="Type"
        value={formValues.type}
        options={clauseTypes}
        onChange={handleChangeClauseType}
        name="clauseType"
        isSearchable={true}
      />
      <InputMultiSelect
        labelText="Tags"
        options={tagsLibraryData.map((tag: Tag) => tag.label)}
        value={tagsLibraryData.filter((tag: Tag) => formValues?.tags?.includes(tag.id)).map((tag: Tag) => tag.label)}
        // @ts-ignore
        onChange={handleChangeClauseTags}
        onSelect={() => {}}
      />
      <div className={`${isAdd ? 'add' : 'edit'}-submission-clause`}>
        <Editor name="clause" content={editorContent} onUpdate={handleChangeClauseContent} />
      </div>
      {isAdd ? (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            disabled={!formValues.description || !editorContent}
            onClick={handleSubmitClause}
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
          >
            Create
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="basis-1/2 w-1/2">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex">
              <button
                onClick={archiveOrRestoreClause}
                type="button"
                className="focus:ring-red-500 bg-red-600 hover:bg-red-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
              >
                {clause.deleted_at ? 'Restore' : 'Archive'}
              </button>
            </div>
          </div>
          <div className="basis-1/2 w-1/2">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                onClick={handleSubmitClause}
                type="button"
                className="focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                onClick={handleClose}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AdminClauseModal;
