import { useEffect, useState } from 'react';

import qs from 'qs';

import type { Tag } from '../../@types/types';
import { fetchTags } from '../queries';

export const useTags = (): Tag[] => {
  const [tagsLibraryData, setTagsLibraryData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const tagsData = await fetchTags(queryParams.authToken as string);
        tagsData?.sort((a, b) => {
          return a.label.trim() > b.label.trim() ? 1 : -1;
        });

        // @ts-ignore
        setTagsLibraryData(tagsData);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return tagsLibraryData;
};
