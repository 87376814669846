import React from 'react';

import { Auth } from '@aws-amplify/auth';
import get from 'lodash.get';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Banner from '../../components/Banner';
import Button from '../../components/Button';
import Form from '../../components/Form';
import InputText from '../../components/InputText';
import useForm from '../../hooks/useForm';
import AuthLayout from './AuthLayout';

const mapFormError = (message: string) => {
  if (message === 'Temporary password has expired and must be reset by an administrator.') {
    return {
      headingText: 'Password Expired',
      text: 'Your temporary password has expired. Please contact an administrator to request a new password.',
    };
  }

  return { headingText: message };
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getFieldProps, canSubmit, onSubmit, formError } = useForm<{ email: string; password: string }>({
    initialState: { email: '', password: '' },
    onSubmit: async (formValues) => {
      const user = await Auth.signIn(formValues.email.trim(), formValues.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return navigate('/setup-password', { state: formValues });
      }
      const pathName = get(location, 'state.from.pathname', '').trim();
      return navigate(pathName && pathName !== '/' ? pathName : '/admin/submissions');
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
  });

  return (
    <AuthLayout>
      {formError && <Banner className="mb-8" color="red" {...mapFormError(formError)} />}

      <Form onSubmit={onSubmit}>
        <InputText
          className="mb-6"
          labelText="Email"
          placeholder="email@example.com"
          {...getFieldProps<string>('email')}
        />

        <InputText
          className="mb-6"
          labelText="Password"
          link={
            <Link className="text-blue-600 font-medium text-sm" to={'/forgot-password'}>
              Forgot password?
            </Link>
          }
          type="password"
          {...getFieldProps('password')}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit} className="w-full">
          Sign in
        </Button>
      </Form>
      <br />
    </AuthLayout>
  );
};

export default Login;
