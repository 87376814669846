import React, { useState, useEffect, useRef } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import type { DownshiftState, StateChangeOptions } from 'downshift';
import Downshift from 'downshift';

import type { UIComponentBase, UIInputValue, UIOnChangeFn, UISelectionType } from '../../@types/types';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputReadOnly from './InputReadOnly';

type InputSearchStyle = 'menuInner' | 'menuItem' | 'menuOuter' | 'menuItemDisabled';
type InputSearchStyles = Record<InputSearchStyle, string>;

export const styles: InputSearchStyles = {
  menuInner: 'overflow-y-auto max-h-96',
  menuItem: 'py-2 px-3 cursor-pointer text-sm font-normal',
  menuOuter:
    'border-gray-300 border rounded absolute inset-x-0 top-0 z-20 bg-white text-gray-900 mt-11  shadow overflow-hidden',
  menuItemDisabled: 'cursor-not-allowed bg-gray-50 text-gray-300',
};

export interface InputSearchProps extends InputFieldProps {
  isReadOnly?: boolean;
  isSearchable?: boolean;
  labelText?: string;
  name?: string;
  options?: UISelectionType[];
  value?: UIInputValue;
  initialSelectedItem?: UIInputValue;
  placeholder?: string;
  'aria-labelledby'?: string;
  formatter?: (value: any) => string | number | false;
  itemFormatter?: (value: any) => string;
  onChange?: UIOnChangeFn;
  onSelect?: UIOnChangeFn;
  buttonClassName?: string;
}

interface MenuContainerProps {
  anchorRef?: React.RefObject<HTMLInputElement>;
  children?: UIComponentBase['children'];
}

type UseDownshiftStyleState = React.CSSProperties | undefined;
type StateReducerFn =
  | ((
      state: DownshiftState<UISelectionType>,
      changes: StateChangeOptions<UISelectionType>
    ) => Partial<StateChangeOptions<UISelectionType>>)
  | undefined;

const useDownshiftStyle = (
  anchorRef?: React.RefObject<HTMLInputElement>,
  ref?: React.RefObject<HTMLInputElement>
): UseDownshiftStyleState => {
  const [style, setStyle] = useState<UseDownshiftStyleState>(undefined);

  useEffect(() => {
    const element = ref?.current;
    const anchorElement = anchorRef?.current;

    if (element && anchorElement) {
      const anchor = anchorElement.getBoundingClientRect();
      const bound = element.getBoundingClientRect();

      // if overflowing
      if (anchor.y + anchor.height > window.innerHeight - 64) {
        const height = bound.bottom - bound.y + 8; // get element height
        const style = { marginTop: -height };
        setStyle({ ...style });
      }
    }
  }, [ref]);

  return style;
};

const stateReducer: StateReducerFn = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return { ...changes, inputValue: '' };

    case Downshift.stateChangeTypes.clickButton:
    case Downshift.stateChangeTypes.changeInput:
      return { ...changes, highlightedIndex: 0 };

    default:
      return changes;
  }
};

export const MenuContainer: React.FC<MenuContainerProps> = ({ anchorRef, children }) => {
  const ref = useRef(null);
  const style = useDownshiftStyle(anchorRef, ref);

  return (
    <div className={styles.menuOuter} ref={ref} style={style}>
      {children}
    </div>
  );
};

const InputSearch: React.FC<InputSearchProps> = ({
  className,
  descriptionText,
  errors,
  isReadOnly,
  isSearchable = true,
  labelText,
  name = 'input',
  options,
  placeholder = 'Select',
  // size = 'normal',
  value,
  id: _id,
  formatter,
  itemFormatter,
  onChange,
  onSelect: _onSelect,
  ...props
}) => {
  const getItemToString = (item: UISelectionType | null): string => {
    return (item?.value ?? '').toString();
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      name={name}
      errors={errors}
      {...props}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={value || ''}
        />
      )}

      {!isReadOnly && (
        <Downshift
          stateReducer={stateReducer}
          // @ts-ignore
          selectedItem={value ?? ''}
          itemToString={getItemToString}
          onSelect={(item: any) => {
            if (onChange) onChange(item?.value, name);
          }}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            highlightedIndex,
            isOpen,
            inputValue,
            getInputProps,
          }) => {
            const normalized = String(inputValue ?? '').toLowerCase();
            const byInputValue = (item: UISelectionType) =>
              String(item.name ?? '')
                .toLowerCase()
                .includes(normalized);
            const filteredItems = options?.filter(byInputValue);
            const selectableItems = isSearchable ? filteredItems : options;

            return (
              <div className="relative flex">
                <div className="relative w-full">
                  <input
                    {...getInputProps()}
                    {...getToggleButtonProps()}
                    type="select"
                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    placeholder={value === undefined ? placeholder : formatter ? formatter(value) : value}
                  />
                  <span className="absolute top-0 right-0 p-2.5 text-sm font-medium text-gray-400 rounded-r-lg focus:ring-4 focus:outline-none">
                    <SearchIcon className="w-5 h-5" />
                    <span className="sr-only">Search</span>
                  </span>
                </div>

                {isOpen && (
                  <MenuContainer>
                    <div {...getMenuProps()}>
                      <ul className={styles.menuInner}>
                        {(selectableItems || []).map((item, index) => {
                          const { isDisabled = false } = item;
                          const result =
                            (itemFormatter && itemFormatter(item.value)) ||
                            (formatter && formatter(item.value)) ||
                            item.name;

                          const { onClick, onMouseDown, onMouseMove, ...itemProps } = getItemProps({ item });

                          const actionProps = isDisabled
                            ? {}
                            : {
                                onClick,
                                onMouseDown,
                                onMouseMove,
                              };

                          return (
                            <li
                              className={clsx(
                                styles.menuItem,
                                index === highlightedIndex && !isDisabled && 'bg-gray-100',
                                isDisabled && styles.menuItemDisabled
                              )}
                              index={index}
                              key={JSON.stringify(item.value)}
                              {...actionProps}
                              {...itemProps}
                            >
                              {result}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </MenuContainer>
                )}
              </div>
            );
          }}
        </Downshift>
      )}
    </InputField>
  );
};

export default InputSearch;
