import React from 'react';

import { get } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';

import resolveSchema from '../../helpers/resolveSchema';
import Button from '../Button';
import Card from '../Card';
import ErrorText from '../ErrorText';
import H4 from '../H4';
import type { RepeaterProps } from './Repeater';

const SectionRepeater: React.FC<RepeaterProps> = ({
  className,
  formValues,
  id,
  item,
  ownKey,
  value,
  validationErrors,
  showQuestionKey,
  onChange,
  mapChildren,
  setFormValues,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [{}];
  const sectionRequiredError = validationErrors?.find((error) => error.path === ownKey && error.keyword === 'required');

  const schemaItem = item.items.type === 'object' ? item.items : { properties: { [id]: item.items } };

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, {}]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex: number) => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  return (
    <div className={className}>
      <H4 className="my-8">{item.title}</H4>

      {initialItems.map((_lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(schemaItem);

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex justify-between items-center p-4">
                <div className="font-medium">
                  {item.items.title} #{index + 1}
                </div>

                {initialItems.length > 1 && (
                  <Button className="h-10 px-3 -m-2" kind="danger-secondary" onClick={() => handleRemove(index)}>
                    Delete
                  </Button>
                )}
              </Card.Header>
            }
          >
            <div className="p-4">
              {mapChildren({
                formValues,
                onChange,
                parentKey: key,
                parentSchema: clonedSchema,
                setFormValues,
                validationErrors,
                showQuestionKey,
                // useParentKey: true,
              })}
            </div>
            {sectionRequiredError && <ErrorText className="p-4 mb-4">Section is required</ErrorText>}
          </Card>
        );
      })}
      <Button onClick={handleAdd}>Add new</Button>
    </div>
  );
};

export default SectionRepeater;
