import React from 'react';

import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';

import { WysiwygManager } from '../containers';
import { DynamicFormProvider } from '../containers/DynamicForm';
import { TemplatesLandingPage } from '../containers/TemplatesLandingPage';
import { getClausesEditorContent } from '../helpers/getClausesEditorContent';
import { resolveRefs } from '../helpers/resolveSchema';
import { useSubmissionData } from '../hooks';

import './index.css';

const SingleSubmission: React.FC = () => {
  const { data, status, isEditorRoute, isMainSubmissionRoute, documentType, isFetching, refetch } = useSubmissionData();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  if (status === 'loading') {
    return (
      <div className=" loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (status === 'error') {
    console.error(`Unable to load submission: ${id}`);
    return (
      <div className="loader flex justify-center items-center">Unable to load submission, please try again later</div>
    );
  }

  const schemas = data?.schemas;
  const detailsData = data?.data?.risk_details;
  const authorised_signatory = detailsData?.authorised_signatory || [];
  const schema = schemas?.[0];
  resolveRefs(schema);

  const initialValues = {
    ...detailsData,
    authorised_signatory,
  };

  if (isMainSubmissionRoute && data?.data?.status !== 'PRE-DRAFT') {
    return <Navigate to={{ pathname: `/submissions/${id}/editor`, search: location.search }} />;
  }

  if (isEditorRoute && data?.data?.status === 'PRE-DRAFT') {
    return <Navigate to={{ pathname: `/submissions/${id}`, search: location.search }} />;
  }

  const entityClauses = data?.data?.clauses ?? [];
  let editorContent = data?.data?.editor_content ?? {};
  if (['MRC', 'ENDORSEMENT'].includes(documentType)) {
    editorContent = { ...editorContent, clause_library: getClausesEditorContent(entityClauses) };
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <TemplatesLandingPage id={data?.data?.id!} details={data?.data?.risk_details} documentType={documentType} />
          }
        />
        <Route
          path={'editor'}
          element={
            <DynamicFormProvider initialValues={initialValues}>
              <WysiwygManager
                itemTags={data?.data?.tags ?? []}
                editorContent={editorContent}
                documentType={documentType}
                status={data?.data?.status}
                isPdfDocumentCreated={data?.data?.document_name ? true : false}
                entityClauses={entityClauses}
                schemas={schemas}
                isFetching={isFetching}
                refetch={refetch}
                visibilityStatus={data?.data?.visibility_status ?? 'DRAFT'}
                publishingOptions={[
                  {
                    title: 'Draft',
                    description: '',
                  },
                  {
                    title: 'Firm Order',
                    description: '',
                  },
                  {
                    title: 'Final',
                    description: '',
                  },
                  { title: 'Archived', description: '' },
                ]}
              />
            </DynamicFormProvider>
          }
        />
      </Routes>
    </>
  );
};

export const SingleSubmissionPage = SingleSubmission;
