import './App.css';

import React, { Fragment, useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import { Transition } from '@headlessui/react';
import { Disclosure, Menu } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { MenuAlt1Icon } from '@heroicons/react/outline';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import qs from 'qs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ServerErrorModal from './components/ServerErrorModal';
import AdminLayout from './containers/Admin/AdminLayout';
import ForgotPassword from './containers/Auth/ForgotPassword';
import Login from './containers/Auth/Login';
import Logout from './containers/Auth/Logout';
import PasswordForm from './containers/Auth/PasswordForm';
import { getInitials } from './helpers/getInitials';
import { useCurrentUser } from './hooks/useCurrentUser';
import {
  AdminDashboard,
  AdminSubmissions,
  AdminTemplates,
  AdminClauses,
  AdminTags,
  SingleSubmissionPage,
  SingleTemplatePage,
  TemplatesPage,
  NotFoundPage,
} from './pages';
import { classNames } from './utils/class-names';

const queryClient = new QueryClient();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

Auth.configure({
  region: 'eu-west-1',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
});

/** Hardcoded submission ID to redirect users to if they land on the `/` route */
/* const SUBMISSION_ID = 'a3518895-ca57-4927-9f74-a4c56784a8d6'; */
/* if (process.env.REACT_APP_MSW_ENABLED === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
} */

const isUserRoute = (pathname: string): boolean => pathname.indexOf('/admin') === -1;

const App: React.FC = () => {
  const { user } = useCurrentUser();
  const { authToken } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [serverErrorShown, setServerErrorShown] = useState(false);
  const [serverErrorDetails, setServerErrorDetails] = useState('');

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      let errorMessage = error.message;
      if (error.response?.data) {
        errorMessage = error.response.data.message || JSON.parse(`${error.response.data.replace(/'/g, '"')}`).message;
      }
      setServerErrorDetails(errorMessage);
      if (serverErrorShown) return;
      setServerErrorShown(true);
      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="relative min-h-screen flex flex-col">
          {isUserRoute(location.pathname) && (
            <Disclosure as="nav" className="flex-shrink-0 bg-gray-900">
              {({ open }) => (
                <>
                  <div className="w-full px-2 sm:px-4 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                      <div className="flex items-center px-2 lg:px-0">
                        <div className="flex-shrink-0">
                          <Logo />
                        </div>
                      </div>
                      <div className="flex lg:hidden">
                        <Disclosure.Button className="bg-gray-600 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                            <MenuAlt1Icon className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="hidden lg:block lg:w-80">
                        <div className="flex items-center justify-end">
                          {!authToken && (
                            <Menu as="div" className="ml-4 relative flex-shrink-0">
                              <div>
                                <Menu.Button className="bg-gray-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white">
                                  <span className="sr-only">Open user menu</span>
                                  <span className="h-8 text-sm flex font-semibold items-center text-gray-400 justify-center w-8">
                                    {user ? getInitials(user) : ''}
                                  </span>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        Settings
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="/logout"
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        Logout
                                      </a>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="lg:hidden">
                    <div className="pt-3 pb-3 border-t border-gray-800">
                      <div className="px-2">
                        <a
                          href="#"
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-gray-100 hover:bg-gray-600"
                        >
                          Settings
                        </a>
                        <a
                          href="#"
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-indigo-100 hover:bg-gray-600"
                        >
                          Sign out
                        </a>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}

          <ServerErrorModal open={serverErrorShown} setOpen={setServerErrorShown} errorDetails={serverErrorDetails} />
          <Routes>
            <Route path="/register" element={<PasswordForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<PasswordForm />} />
            <Route path="/submissions/:id/*" element={<SingleSubmissionPage />} />
            <Route path="/templates/:id/*" element={<SingleTemplatePage />} />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="submissions" element={<AdminSubmissions />} />
              <Route path="templates" element={<AdminTemplates />} />
              <Route path="clauses" element={<AdminClauses />} />
              <Route path="tags" element={<AdminTags />} />
            </Route>
            <Route path="/" element={<NotFoundPage />} />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;

const Logo = () => {
  return <img src="/Ed_Logo_White.png" className="h-6" />;
};
