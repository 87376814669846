import React from 'react';

import clsx from 'clsx';

import './Card.css';
import type { FilterSearchType, Dropdown, UIInputValue } from '../../../../@types/types';
import type { UIOnChangeFn } from '../../../../@types/types';
import InputFilterSearch from '../../../components/InputFilterSearch';
import InputSelect from '../../../components/InputSelect';

interface CardProps {
  innerContent: any;
  IconComponent: React.ReactNode;
  footer?: any;
  title: string;
  dropdown?: Dropdown;
  filterSearch?: FilterSearchType;
  kind?: 'default' | 'contract';
  selectedContract?: UIInputValue;
  handleSelect?: UIOnChangeFn;
}

const Card: React.FC<CardProps> = ({
  innerContent,
  IconComponent,
  footer,
  title,
  dropdown,
  filterSearch,
  kind = 'default',
  selectedContract,
  handleSelect,
}) => {
  const variants = {
    footer: {
      contract: !!selectedContract && 'text-indigo-700',
      default: 'hover:text-indigo-700',
    },
  };

  const handleFooterClick = () => {
    footer?.action && footer?.action(selectedContract);
  };

  return (
    <div className="card relative bg-white rounded-2xl shadow-xl flex flex-col flex-auto">
      <div className="brand-icon rounded-xl text-white">{IconComponent}</div>
      <div className="p-6 flex flex-col flex-auto h-full">
        <h3 className="text-xl leading-7 font-medium text-gray-900 pt-10">{title}</h3>
        <div className="my-6">
          {typeof innerContent === 'string' ? (
            <p className="text-base leading-6 font-normal text-gray-500">{innerContent}</p>
          ) : (
            innerContent()
          )}
        </div>
        {dropdown && dropdown.options && (
          <InputSelect
            // @ts-ignore
            onChange={handleSelect}
            options={dropdown.options}
            placeholder={dropdown.placeholder}
            formatter={dropdown.formatter}
            value={selectedContract}
          />
        )}
        {filterSearch && <InputFilterSearch {...filterSearch} />}
      </div>
      {footer && (
        <div
          className={clsx(
            'bg-gray-50 p-6 mt-auto rounded-b-2xl flex align-center text-gray-400 text-base leading-6 font-normal cursor-pointer',
            variants.footer[kind]
          )}
          onClick={handleFooterClick}
        >
          {footer.content}&nbsp;&#8594;
        </div>
      )}
    </div>
  );
};

export default Card;
