import qs from 'qs';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import type { EDDocumentType, ServerResponse, Submission, UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import { fetchSubmission } from '../queries';

interface SubmissionDataHook {
  data: ServerResponse<Submission> | undefined;
  status: UseQueryStatus;
  isEditorRoute: boolean;
  isMainSubmissionRoute: boolean;
  documentType: EDDocumentType | '';
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useSubmissionData = (): SubmissionDataHook => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const isEditorRoute = location.pathname.includes(`/submissions/${id}/editor`);
  const isMainSubmissionRoute =
    location.pathname === `/submissions/${id}` || location.pathname === `/submissions/${id}/`;
  const queryParams: { authToken?: string } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { data, status, isFetching, refetch } = useQuery(
    ['submission', id],
    () => fetchSubmission(id!, queryParams?.authToken ?? ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    status,
    isEditorRoute,
    isMainSubmissionRoute,
    documentType: data?.data?.document_type ?? '',
    isFetching,
    refetch,
  };
};
