import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';

import qs from 'qs';

import type { TagShort } from '../../../../../@types/types';
import InputText from '../../../../components/InputText';
import Modal from '../../../../components/Modal';
import { createTag, deleteTag, updateTag } from '../../../../mutations';

const AdminTagModal = ({
  tag = {
    id: '',
    label: '',
    created_at: '',
  },
  handleClose,
  setShouldFetchData,
  isAdd,
}: {
  tag: TagShort;
  handleClose: () => void;
  setShouldFetchData: Dispatch<SetStateAction<boolean>>;
  isAdd: boolean;
}) => {
  const [formValues, setFormValues] = useState(tag);

  const handleChangeTagLabel = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, label: target.value });

  const handleSubmit = async () => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { id, ...restFormValues } = formValues;
      if (isAdd) {
        await createTag({ ...restFormValues }, queryParams.authToken as string);
      } else {
        await updateTag(id, { ...restFormValues }, queryParams.authToken as string);
      }
      setShouldFetchData(true);
    } catch (e) {
      console.log(e);
    }
  };

  const removeTag = async () => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      await deleteTag(tag.id, queryParams.authToken as string);
      setShouldFetchData(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open className="w-full lg:w-1/2 sm:w-5/6" onClose={handleClose} title={isAdd ? 'Create tag' : 'Edit tag'}>
      <InputText labelText="Label" isRequired value={formValues.label} onChange={handleChangeTagLabel} />
      {isAdd ? (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            disabled={!formValues.label}
            onClick={handleSubmit}
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
          >
            Create
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="basis-1/2 w-1/2">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex">
              <button
                onClick={removeTag}
                type="button"
                className="focus:ring-red-500 bg-red-600 hover:bg-red-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm"
              >
                Delete
              </button>
            </div>
          </div>
          <div className="basis-1/2 w-1/2">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                onClick={handleSubmit}
                type="button"
                className="focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                onClick={handleClose}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AdminTagModal;
