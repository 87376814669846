// @ts-ignore
import type { CommandProps } from '@tiptap/core';
// @ts-ignore
import { findParentNodeClosestToPos, getNodeAttributes } from '@tiptap/core';
import TableExtenstion from '@tiptap/extension-table';

import { findFirstNodeAbove } from './helpers';

export const Table = () => {
  const handleBackspace = (editor: any) => {
    const { selection } = editor.state;
    const parentOffset = selection.$head.parentOffset;

    const tableCell = findParentNodeClosestToPos(selection.ranges[0].$from, (node: any) => {
      return node.type.name === 'tableCell';
    });

    const { size } = tableCell?.node?.content || {};

    if (parentOffset === 0 && size !== undefined && size <= 2) {
      return editor.chain().deleteRow().run();
    }
  };

  return TableExtenstion.extend({
    addOptions() {
      return {
        ...this.parent?.(),
        allowTableNodeSelection: true,
        resizable: true,
      };
    },
    addAttributes() {
      const parent = this.parent ? this.parent() : {};
      return {
        ...parent,
        bold: {
          default: null,
        },
        class: {
          default: null,
        },
      };
    },

    addCommands() {
      const parent = this.parent ? this.parent() : {};
      return {
        ...parent,
        toggleTableBold: () => (args: CommandProps) => {
          const attrs = getNodeAttributes(args.state, this.name);
          const node = findFirstNodeAbove(this.name, args.state.selection);
          args.tr.setNodeMarkup(node.before, node.node.type, { ...node.node.attrs, bold: !attrs?.bold || null });
        },
      };
    },

    addKeyboardShortcuts() {
      return {
        Tab: () => {
          return false;
        },
        'Shift-Tab': () => false, // both shortcuts are dealt with in the Ident module
        Backspace: () => handleBackspace(this.editor),
        'Mod-Backspace': () => handleBackspace(this.editor),
        Delete: () => handleBackspace(this.editor),
        'Mod-Delete': () => handleBackspace(this.editor),
        'Shift-Enter': () => {
          return this.editor.chain().addRowAfter().goToNextCell().run();
        },
      };
    },
  });
};
