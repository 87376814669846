import React, { useEffect } from 'react';

import moment from 'moment';

import type { Column, DropdownFilter, Filter, Tag } from '../../../@types/types';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { useTags } from '../../hooks';
import { fetchSubmissions } from '../../queries';
import { Table } from './components';
import ItemTags from './components/ItemTags';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [
  {
    label: 'Unique Market Reference',
    key: 'risk_details.umr',
    type: 'dropdown',
    placeholder: 'All UMRs',
    options: [],
    selected: [],
  },
  {
    label: 'Document types',
    key: 'document_type',
    type: 'dropdown',
    placeholder: 'All types',
    options: [
      'ENDORSEMENT',
      'EOC_BINDER',
      'EOC_OPEN_MARKET',
      'EOC_OPEN_MARKET_BERMUDA',
      'BERMUDA_DECLARATION',
      'BERMUDA_DECLARATION_RELM_EO_CYBER',
      'BERMUDA_MRC',
      'MRC',
    ],
    selected: [],
  },
  {
    label: 'Visibility status',
    key: 'visibility_status',
    type: 'dropdown',
    placeholder: 'All statuses',
    options: ['DRAFT', 'FIRM ORDER', 'FINAL', 'ARCHIVED'],
    selected: [],
  },
  {
    label: 'Tags',
    key: 'tags',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
];

const columns: Column[] = [
  {
    label: 'UMR',
    key: 'risk_details.umr',
    headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    dataCellClassName: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
  },
  {
    label: 'Type',
    key: 'document_type',
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Tags',
    key: 'tags',
    dataCellClassName: '',
    render: (item: any, tags: Tag[], setIsLoading) => <ItemTags item={item} tags={tags} setIsLoading={setIsLoading} />,
  },
  {
    label: 'Visibility',
    key: 'visibility_status',
    sortable: true,
  },
  {
    label: 'Version',
    key: 'version',
  },
  {
    label: 'Show',
    key: 'show',
    type: 'link',
    href: '/submissions/{id}',
    target: '_blank',
    rel: 'noreferrer',
  },
];

export const Submissions: React.FC = () => {
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection } = useDataQuery(
    'submissions',
    initialFilters,
    fetchSubmissions,
    (data) => {
      const umrsFilterOptions = data?.reduce((prev: string[], curr: { risk_details: { umr: string } }) => {
        if (prev.indexOf(curr.risk_details?.umr as string) === -1) {
          prev.push(curr.risk_details?.umr as string);
        }
        return prev;
      }, []);
      umrsFilterOptions.sort();

      const clonedFilters = [...filters];
      (
        clonedFilters[clonedFilters.findIndex((filter: Filter) => filter.key === 'risk_details.umr')] as DropdownFilter
      ).options = umrsFilterOptions;
      setFilters([...clonedFilters]);
    }
  );

  const tagsLibraryData = useTags();

  useEffect(() => {
    const clonedFilters = [...filters];
    (clonedFilters[clonedFilters.findIndex((filter: Filter) => filter.key === 'tags')] as DropdownFilter).options =
      tagsLibraryData.map((tag: Tag) => tag.label);
    setFilters(clonedFilters);
  }, [tagsLibraryData]);

  return (
    <Table
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data?.map((submission: { document_type: string }) =>
        ['MRC', 'ENDORSEMENT'].includes(submission.document_type)
          ? submission
          : { ...submission, visibility_status: 'N/A' }
      )}
      status={status}
      tags={tagsLibraryData}
    />
  );
};

export const AdminSubmissions = withPrivateRoute({ adminOnly: true })(Submissions);
