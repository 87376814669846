import React from 'react';

import type { S3SchemaEnum } from '../../../@types/types';
import type { InputSelectProps } from '../InputSelect';
import InputSelect from '../InputSelect';

interface InputSelectSchemaProps extends InputSelectProps {
  item: { enum: S3SchemaEnum[] };
}

const InputSelectSchema: React.FC<InputSelectSchemaProps> = ({ item, ...props }) => (
  <InputSelect
    {...props}
    options={item?.enum?.map((value) =>
      typeof value === 'object' ? { name: value.name, value: value.value } : { name: value, value }
    )}
  />
);

export default InputSelectSchema;
