import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const updateTag = async (id: string, data: Pick<Tag, 'label'>, authToken: string): Promise<Pick<Tag, 'id'>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'patch',
    url: `${process.env.REACT_APP_API_URL}/tags-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
