import React, { useEffect, useState } from 'react';

import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import get from 'lodash.get';
import { useLocation } from 'react-router-dom';

import type { SetIsLoading, Tag } from '../../../../@types/types';
import InputMultiSelect from '../../../components/InputMultiSelect';
import { updateSubmission, updateTemplate, updateClauseInLibrary } from '../../../mutations';
import { fetchSubmission, fetchTemplate, fetchClauseFromLibrary } from '../../../queries';

const ItemTags = (props: { item: any; tags: Tag[]; setIsLoading?: SetIsLoading }) => {
  const { pathname } = useLocation();
  const [updatedTagsIds, setUpdatedTagsIds] = useState(null);
  const initialTagsLabels = (updatedTagsIds ?? get(props.item, 'tags', []))
    ?.map((tagId: string) => props.tags.find((tag: Tag) => tag.id === tagId)?.label)
    ?.filter((label: string) => label);
  const [itemTagsLabels, setItemTagsLabels] = useState(initialTagsLabels);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdatingItemTags, setIsUpdatingItemTags] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  useEffect(() => {
    if (typeof props.setIsLoading === 'function') {
      props.setIsLoading(isUpdatingItemTags);
    }
  }, [isUpdatingItemTags]);
  useEffect(() => {
    setItemTagsLabels(initialTagsLabels);
  }, [props.tags.length]);
  const toggleEditMode = () => setIsEditMode(!isEditMode);
  const handleChangeItemTags = (itemTagsLabels: any) => setItemTagsLabels(itemTagsLabels);
  const handleCancelUpdateItemTags = () => {
    setItemTagsLabels(initialTagsLabels);
    toggleEditMode();
  };
  const handleUpdateItemTags = async () => {
    try {
      setIsUpdatingItemTags(true);
      let updatedTagIds;
      if (pathname.startsWith('/admin/submissions')) {
        await updateSubmission(
          props.item.id,
          {
            tags: itemTagsLabels?.map((label: string) => props.tags.find((i: Tag) => i.label === label)?.id),
          },
          ''
        );
        // @ts-ignore
        const { data } = await fetchSubmission(props.item.id);
        updatedTagIds = data.tags;
      } else if (pathname.startsWith('/admin/templates')) {
        await updateTemplate(
          props.item.id,
          {
            tags: itemTagsLabels?.map((label: string) => props.tags.find((i: Tag) => i.label === label)?.id),
          },
          ''
        );
        // @ts-ignore
        const { data } = await fetchTemplate(props.item.id);
        updatedTagIds = data.tags;
      } else if (pathname.startsWith('/admin/clauses')) {
        await updateClauseInLibrary(
          props.item.id,
          {
            tags: itemTagsLabels?.map((label: string) => props.tags.find((i: Tag) => i.label === label)?.id),
          },
          ''
        );
        // @ts-ignore
        const { data } = await fetchClauseFromLibrary(props.item.id);
        updatedTagIds = data.tags;
      }
      if (updatedTagIds) {
        // @ts-ignore
        setUpdatedTagsIds(updatedTagIds);
      }
      toggleEditMode();
    } catch (e) {
      console.log(e);
      setItemTagsLabels(initialTagsLabels);
    } finally {
      setIsUpdatingItemTags(false);
    }
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return isEditMode ? (
    <td>
      <div className="flex items-center item-tags-edit-mode">
        <InputMultiSelect
          value={itemTagsLabels}
          options={props.tags.map((i: Tag) => i.label)}
          onChange={handleChangeItemTags}
          onSelect={() => {}}
          isDisabled={isUpdatingItemTags}
          className="w-auto"
        />
        <div
          className="cursor-pointer p-1 hover:bg-gray-50 rounded-full text-gray-500"
          onClick={handleCancelUpdateItemTags}
        >
          <XIcon className="h-4 w-4" />
        </div>
        <div className="cursor-pointer p-1 hover:bg-gray-50 rounded-full text-gray-500" onClick={handleUpdateItemTags}>
          <CheckIcon className="h-4 w-4" />
        </div>
      </div>
    </td>
  ) : (
    <td onMouseOver={handleMouseEnter} onMouseOut={handleMouseLeave}>
      {itemTagsLabels?.map((tagLabel: string) => (
        <span
          key={tagLabel}
          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
        >
          {tagLabel}
        </span>
      ))}
      <span
        className={clsx(
          'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 opacity-0',
          isHovering && 'opacity-100'
        )}
        onClick={toggleEditMode}
      >
        <span className="cursor-pointer hover:text-gray-500 rounded-full">
          <PencilIcon className="h-4 w-4" />
        </span>
      </span>
    </td>
  );
};

export default ItemTags;
