import axios from 'axios';

import type { SubmissionPayload, Submission, ServerResponse } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const updateSubmission = async (
  id: string,
  data: SubmissionPayload,
  authToken: string
): Promise<ServerResponse<Submission>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'patch',
    url: `${process.env.REACT_APP_API_URL}/submissions-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
