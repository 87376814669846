import React, { useEffect, useState } from 'react';

import moment from 'moment';

import type { Action, Column, Filter, Tag } from '../../../@types/types';
import { MODALS } from '../../constants';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { useModal } from '../../hooks';
import { fetchTags } from '../../queries';
import { Table } from './components';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [];

const initialColumns: Column[] = [
  {
    label: 'Label',
    key: 'label',
    sortable: true,
    normalizer: (fieldValue = '') => fieldValue.toLowerCase(),
    headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    dataCellClassName: 'truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Edit',
    key: 'edit',
    type: 'link',
    href: '#',
  },
];

export const Tags: React.FC = () => {
  const { showModal } = useModal();
  const [columns, setColumns] = useState(initialColumns);
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection, setShouldFetchData } = useDataQuery(
    'tags',
    initialFilters,
    fetchTags
  );

  const handleEditTagClick = (tag: Tag) => () => showModal(MODALS.ADMIN_TAG, { tag, setShouldFetchData, isAdd: false });

  useEffect(() => {
    const clonedColumns = [...columns];
    clonedColumns[clonedColumns.findIndex((column: Column) => column.key === 'edit')].onClick = handleEditTagClick;
    setColumns(clonedColumns);
  }, []);

  const actions: Action[] = [
    {
      key: 'add_tag',
      label: 'New Tag',
      onClick: () => {
        showModal(MODALS.ADMIN_TAG, { setShouldFetchData, isAdd: true });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data}
      status={status}
    />
  );
};

export const AdminTags = withPrivateRoute({ adminOnly: true })(Tags);
