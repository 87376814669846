import type React from 'react';
import { useState, useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';

import type { AppError, EDDocumentType, S3Schema } from '../@types/types';
import { useDynamicForm } from './containers/DynamicForm';
import resolveSchema from './helpers/resolveSchema';
import {
  useBermudaDeclarationEffects,
  useBermudaDeclarationRelmEoCyberEffects,
  useBermudaMrcEffects,
  useEocBinderEffects,
  useEocOpenMarketEffects,
  useEocOpenMarketBermudaEffects,
} from './hooks';
import mapChildren from './mapChildren';

interface DatapointsProps {
  schema: S3Schema;
  documentType: EDDocumentType | '';
  isDisabled: boolean;
  name: string;
  formErrors: AppError[];
  setFormErrors: (errors: AppError[]) => void;
}

const Datapoints: React.FC<DatapointsProps> = ({ schema, isDisabled, documentType, formErrors, setFormErrors }) => {
  const { formValues, onChange, setFormValues, getFormState } = useDynamicForm();
  const clonedSchema = cloneDeep(schema);
  const [resolvedSchema, setResolvedSchema] = useState(clonedSchema);
  const isEocBinder = documentType === 'EOC_BINDER';
  const isEocOpenMarket = documentType === 'EOC_OPEN_MARKET';
  const isEocOpenMarketBermuda = documentType === 'EOC_OPEN_MARKET_BERMUDA';
  const isBermudaMrc = documentType === 'BERMUDA_MRC';
  const isBermudaDeclaration = documentType === 'BERMUDA_DECLARATION';
  const isBermudaDeclarationRelmEoCyber = documentType === 'BERMUDA_DECLARATION_RELM_EO_CYBER';

  useEffect(() => {
    setResolvedSchema(resolveSchema(clonedSchema, formValues));
  }, [formValues]);

  if (isEocOpenMarket) {
    useEocOpenMarketEffects(schema, formValues, formErrors, setFormValues, getFormState, setFormErrors);
  }
  if (isEocOpenMarketBermuda) {
    useEocOpenMarketBermudaEffects(schema, formValues, formErrors, setFormValues, getFormState, setFormErrors);
  }
  if (isEocBinder) {
    useEocBinderEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaMrc) {
    useBermudaMrcEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaDeclaration) {
    useBermudaDeclarationEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaDeclarationRelmEoCyber) {
    useBermudaDeclarationRelmEoCyberEffects(schema, formValues, setFormValues, getFormState);
  }

  const handleChange = async (...args: any) => {
    onChange(...args);
  };

  return mapChildren({
    formValues,
    onChange: handleChange,
    parentKey: '',
    isReadOnly: isDisabled,
    parentSchema: resolvedSchema,
    setFormValues,
    validationErrors: formErrors,
    showQuestionKey: true,
  });
};

export default Datapoints;
