import React, { useEffect } from 'react';

import clsx from 'clsx';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import sortBy from 'lodash.sortby';

import type {
  HumanReadableError,
  S3Schema,
  S3SchemaProperties,
  UIChildrenMappingProps,
  UIInputValue,
} from '../@types/types';
import formatErrors from './formatErrors';
import { getComponentType } from './getComponentType';

interface MapChildrenArgs extends UIChildrenMappingProps {
  noErrorLabel?: boolean;
  useParentKey?: boolean;
  isReadOnly: boolean;
  parentKey: string;
  parentSchema: S3Schema;
  onBlur?: () => void;
}

type MapChildrenFn = React.FC<MapChildrenArgs>;

interface MakeComponentProps extends Omit<MapChildrenArgs, 'validationErrors'> {
  item: S3SchemaProperties & { key: string };
  replace: [string, string | UIInputValue] | null;
  mapChildren: MapChildrenFn;
  validationErrors: HumanReadableError[] | null;
}

export const MakeComponent: React.FC<MakeComponentProps> = ({
  ctx,
  formValues,
  isReadOnly,
  item,
  replace,
  noErrorLabel,
  parentKey,
  parentSchema,
  showQuestionId,
  useParentKey,
  validationErrors,
  showQuestionKey,
  onBlur,
  onChange,
  mapChildren,
  setFormValues,
}) => {
  const Component = getComponentType(item);
  if (!Component) return null;
  const isHidden = get(item, 'ui:hidden');
  const constantValue = get(item, 'const');
  let ownKey = parentKey === '' ? item.key : [parentKey, item.key].join('.');
  const schemaPropertiesKeys = Object.keys(parentSchema.properties);

  if (useParentKey) {
    ownKey = parentKey;
  }

  useEffect(() => {
    if (constantValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, ownKey, constantValue);
      setFormValues(clonedFormValues);
    }
  }, [constantValue]);

  useEffect(() => {
    const fieldsWithDefaults = schemaPropertiesKeys.filter(
      (i) => parentSchema.properties[i].value !== undefined && !(i in formValues)
    );
    if (fieldsWithDefaults.length) {
      const nextFormValues = cloneDeep(formValues);
      fieldsWithDefaults.forEach((i) => set(nextFormValues, i, parentSchema.properties[i].value));
      setFormValues(nextFormValues);
    }
  }, [schemaPropertiesKeys]);

  const itemTitle = replace && replace[1] ? item.title.replace(replace[0], replace[1]) : item.title;

  return (
    <Component
      className={clsx('py-2', isHidden && 'hidden')}
      ctx={ctx}
      descriptionText={get(item, 'ui:description')}
      errors={validationErrors?.find((error) => error?.path === ownKey)?.message}
      formValues={formValues}
      id={item.key}
      isHidden={isHidden}
      isReadOnly={isReadOnly || get(item, 'ui:component:readonly')}
      isRequired={[...get(parentSchema, ['required'], [])].includes(item.key)}
      isSearchable={'enum' in item && item.isSearchable}
      item={item}
      key={item.key}
      keyFormValues={formValues[parentKey]}
      labelText={
        (showQuestionId && `${item.qid}. ${itemTitle}`) || (showQuestionKey && <span>{itemTitle}</span>) || itemTitle
      }
      MakeComponent={MakeComponent}
      mapChildren={mapChildren}
      name={ownKey}
      noErrorLabel={noErrorLabel}
      onBlur={onBlur}
      onChange={onChange}
      options={'enum' in item ? item.enum : undefined}
      ownKey={ownKey}
      parentKey={parentKey}
      parentSchema={parentSchema}
      setFormValues={setFormValues}
      showQuestionId={showQuestionId}
      showQuestionKey={showQuestionKey}
      validationErrors={validationErrors}
      value={get(formValues, ownKey, item.value)}
    />
  );
};

const mapChildren: MapChildrenFn = ({
  ctx,
  formValues = {},
  isReadOnly = false,
  noErrorLabel,
  parentKey,
  parentSchema,
  useParentKey,
  validationErrors,
  showQuestionId,
  showQuestionKey,
  onBlur,
  onChange,
  setFormValues,
}) => {
  const mappedProperties = Object.keys(parentSchema.properties).map((key) => ({
    key,
    ...parentSchema.properties[key],
  }));
  const sortedProperties = sortBy(mappedProperties, (c) => c.qid);

  return (
    <>
      {sortedProperties.map((item) => (
        <MakeComponent
          ctx={ctx}
          formValues={formValues}
          isReadOnly={isReadOnly}
          item={item}
          replace={
            Array.isArray(item.replace) && item.replace.length === 2
              ? [item.replace[0], formValues[item.replace[1]] as string]
              : null
          }
          key={item.key}
          mapChildren={mapChildren}
          noErrorLabel={noErrorLabel}
          onBlur={onBlur}
          onChange={onChange}
          parentKey={parentKey}
          parentSchema={parentSchema}
          setFormValues={setFormValues}
          showQuestionId={showQuestionId}
          showQuestionKey={showQuestionKey}
          useParentKey={useParentKey}
          validationErrors={formatErrors(validationErrors)}
        />
      ))}
    </>
  );
};

export default mapChildren;
