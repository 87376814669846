import React, { useEffect, useState } from 'react';

import { DuplicateIcon, HandIcon } from '@heroicons/react/outline';
import qs from 'qs';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import type { Submission, SubmissionPayload, Tag, Template, UIInputValue } from '../../../../@types/types';
import Button from '../../../components/Button';
import { TAGS } from '../../../constants';
import { updateSubmission } from '../../../mutations';
import Card from './Card';
import './InnerContent.css';

interface InnerContentProps {
  id: string;
  templates: any[];
  tags: any[];
  submissions: any[];
  submissionUmr: string;
  documentType: string;
}

export const InnerContent: React.FC<InnerContentProps> = ({
  id,
  templates,
  tags,
  submissions,
  submissionUmr,
  documentType,
}) => {
  const [selectedContract, setSelectedContract] = useState<UIInputValue | never>();
  const [selectedTag, setSelectedTag] = useState<UIInputValue | never>();
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [filteredTags, setFilteredTags] = useState<Tag[]>(tags);
  const [selectedSubmissionContract, setSelectedSubmissionContract] = useState<UIInputValue | never>();
  const [selectedSubmissionTag, setSelectedSubmissionTag] = useState<UIInputValue | never>();
  const [filteredSubmissions, setFilteredSubmissions] = useState<Submission[]>(submissions);
  const [filteredSubmissionTags, setFilteredSubmissionTags] = useState<Tag[]>(tags);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const templatesForFurtherFiltering =
    documentType === 'MRC' ? templates.filter((template) => template.isGeneric) : templates;

  useEffect(() => {
    templatesForFurtherFiltering.sort((a, b) => {
      return a?.risk_details?.umr > b?.risk_details.umr ? 1 : -1;
    });
    setFilteredTemplates(templatesForFurtherFiltering);
  }, []);

  useEffect(() => {
    if (!selectedTag) {
      return;
    }
    const selectedTagId = (selectedTag as unknown as Tag).id;
    const isAllTags = selectedTagId === TAGS.ALL_TAGS_ID;
    const newFilteredTemplates = isAllTags
      ? templatesForFurtherFiltering
      : templatesForFurtherFiltering.filter((template) => template.tags?.includes(selectedTagId));
    newFilteredTemplates.sort((a, b) => {
      return a?.risk_details?.umr > b?.risk_details.umr ? 1 : -1;
    });
    setFilteredTemplates(newFilteredTemplates);

    if (!isAllTags) {
      setSelectedContract(
        templates.find(
          // @ts-ignore
          (template: Template) => template?.sk === selectedContract?.sk && template?.tags?.includes(selectedTagId)
        )
      );
    }

    setFilteredTags(tags.filter((tag: Tag) => tag.id !== selectedTagId));
  }, [selectedTag]);

  useEffect(() => {
    if (!selectedSubmissionTag) {
      return;
    }
    const selectedTagId = (selectedSubmissionTag as unknown as Tag).id;
    const isAllTags = selectedTagId === TAGS.ALL_TAGS_ID;
    const newFilteredSubmissions = isAllTags
      ? submissions
      : submissions.filter((submission) => submission.tags?.includes(selectedTagId));
    newFilteredSubmissions.sort((a, b) => {
      return a?.risk_details?.umr > b?.risk_details.umr ? 1 : -1;
    });
    setFilteredSubmissions(newFilteredSubmissions);

    if (!isAllTags) {
      setSelectedSubmissionContract(
        submissions.find(
          (submission: Submission) =>
            // @ts-ignore
            submission?.sk === selectedSubmissionContract?.sk && submission?.tags?.includes(selectedTagId)
        )
      );
    }

    setFilteredSubmissionTags(tags.filter((tag: Tag) => tag.id !== selectedTagId));
  }, [selectedSubmissionTag]);

  const { mutateAsync, isLoading } = useMutation(
    (values: SubmissionPayload) => updateSubmission(id, values, queryParams.authToken as string),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['submission', id]);
        return navigate(`${location.pathname}/editor${location.search}`);
      },
    }
  );

  const handleSelectContract = async (value: UIInputValue) => {
    if (!value) {
      return;
    }
    const selectedTemplate = templates.find((item) => item.sk === (value as unknown as Submission).sk);

    if (selectedTemplate) {
      await mutateAsync({
        copy_from_template: selectedTemplate.id,
        risk_details: { umr: submissionUmr },
      });
    } else {
      return navigate(`${location.pathname}/editor${location.search}`);
    }
  };

  const handleSelectTag = (tag: UIInputValue) => {
    setSelectedTag(tag);
  };

  const handleSelectSubmissionContract = async (value: UIInputValue) => {
    if (!value) {
      return;
    }
    const selectedSubmission = submissions.find((item) => item.sk === (value as unknown as Submission).sk);

    if (selectedSubmission) {
      await mutateAsync({ copy_from_submission: selectedSubmission.id, risk_details: { umr: submissionUmr } });
    } else {
      return navigate(`${location.pathname}/editor${location.search}`);
    }
  };

  const handleSelectSubmissionTag = (tag: UIInputValue) => {
    setSelectedSubmissionTag(tag);
  };

  if (isLoading) {
    return (
      <div className="loader flex justify-center items-center bg-gray-50">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-start h-full">
      <div className="inner-content p-16">
        <h1 className="text-3xl font-extrabold mt-6 text-white pb-5">Select your contract</h1>
        <p className="text-xl leading-7 font-normal text-gray-300 max-w-3xl">
          Welcome to the contract builder, please select the type of contract you require below to get started.
        </p>
      </div>
      <div className="p-12 flex justify-between space-x-8 -mt-24">
        <Card
          kind="contract"
          IconComponent={<DuplicateIcon />}
          innerContent="The generic base template can be used for all lines of business"
          title="Base templates"
          selectedContract={selectedContract}
          filterSearch={{
            filterDropdown: {
              options:
                (selectedTag as unknown as Tag)?.id === TAGS.ALL_TAGS_ID || !selectedTag
                  ? filteredTags
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((tag: Tag) => ({
                        name: tag.label,
                        value: { id: tag.id, label: tag.label },
                      }))
                  : [
                      TAGS.ALL_TAGS_OPTION,
                      ...filteredTags
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((tag: Tag) => ({
                          name: tag.label,
                          value: { id: tag.id, label: tag.label },
                        })),
                    ],
              onChange: handleSelectTag,
              value: selectedTag === TAGS.ALL_TAGS_ID ? undefined : selectedTag,
              formatter: (value) => value?.label,
              placeholder: TAGS.ALL_TAGS_LABEL,
            },
            searchDropdown: {
              options: filteredTemplates.map((template) => ({
                name: template.risk_details?.umr,
                value: { sk: template.sk, umr: template.risk_details?.umr },
              })),
              onChange: handleSelectContract,
              value: selectedContract,
              formatter: (value) => value?.umr,
              placeholder: 'Select a template',
            },
          }}
        />
        <Card
          kind="contract"
          IconComponent={<DuplicateIcon />}
          innerContent={`Existing ${documentType}s can be copied to base your new ${documentType} from. You can specify tags and search by UMR to identify a ${documentType}`}
          title={`Copy existing ${documentType}`}
          selectedContract={selectedSubmissionContract}
          filterSearch={{
            filterDropdown: {
              options:
                (selectedSubmissionTag as unknown as Tag)?.id === TAGS.ALL_TAGS_ID || !selectedSubmissionTag
                  ? filteredSubmissionTags
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((tag: Tag) => ({
                        name: tag.label,
                        value: { id: tag.id, label: tag.label },
                      }))
                  : [
                      TAGS.ALL_TAGS_OPTION,
                      ...filteredSubmissionTags
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((tag: Tag) => ({
                          name: tag.label,
                          value: { id: tag.id, label: tag.label },
                        })),
                    ],
              onChange: handleSelectSubmissionTag,
              value: selectedSubmissionTag === TAGS.ALL_TAGS_ID ? undefined : selectedSubmissionTag,
              formatter: (value) => value?.label,
              placeholder: TAGS.ALL_TAGS_LABEL,
            },
            searchDropdown: {
              options: filteredSubmissions.map((submission) => ({
                name: `${submission.risk_details?.umr}${
                  submission.risk_details?.description ? ` - ${submission.risk_details?.description}` : ''
                }`,
                value: { sk: submission.sk, umr: submission.risk_details?.umr },
              })),
              onChange: handleSelectSubmissionContract,
              value: selectedSubmissionContract,
              formatter: (value) => value?.umr,
              placeholder: 'Select a submission',
            },
          }}
        />
        <Card
          IconComponent={<HandIcon />}
          innerContent="If you can not find the base template you are looking for, make a request for it to be added"
          title="Request a template"
          footer={{
            content: (
              <a href="mailto:documentproduction@edbroking.com?subject=BASE%20TEMPLATE%20REQUEST">Make a request</a>
            ),
          }}
        />
      </div>
      <div className="flex justify-between items-center ml-12 mr-12 border-t border-gray-200 py-5">
        <div className="flex flex-col justify-start items-start w-full">
          <h5 className="text-gray-400 text-sm leading-5 font-semibold tracking-wider uppercase">
            Feedback or Comments?
          </h5>
          <span className="text-gray-500">
            We would greatly appreciate your feedback or comments to help us improve the system
          </span>
        </div>
        <Button kind="ghost" className="w-1/5" size="small">
          <a
            className="text-gray-500 text-base leading-6 font-normal"
            href="mailto:documentproduction@edbroking.com?subject=User%20Feedback"
          >
            Provide feedback
          </a>
        </Button>
      </div>
    </div>
  );
};
