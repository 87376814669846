import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase } from '../../../@types/types';

const AuthLayout: React.FC<UIComponentBase> = ({ children }) => (
  <div
    className="h-screen flex w-full bg-center bg-cover items-center"
    style={{
      backgroundColor: '#f9fafb',
    }}
  >
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div
        className={clsx(
          'bg-white w-full max-w-96 xl:max-w-128 shadow-xl flex flex-col overflow-y-auto pt-8 rounded ml-auto'
        )}
      >
        <div className="flex justify-center">
          <img src="/logo512.png" className="w-56 self-center mb-10" alt="ED Broking Logo" />
        </div>
        <div className="px-10 mt-8 flex-auto pb-10">{children}</div>
      </div>
    </div>
  </div>
);

export default AuthLayout;
