import React, { useEffect, useState } from 'react';

import moment from 'moment';

import type { Action, Column, Filter, ClauseShort, CheckboxFilter } from '../../../@types/types';
import type { DropdownFilter, Tag } from '../../../@types/types';
import { MODALS } from '../../constants';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { useModal, useTags } from '../../hooks';
import { fetchClauseLibrary } from '../../queries';
import { Table } from './components';
import ItemTags from './components/ItemTags';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [
  {
    label: 'Description',
    key: 'description',
    type: 'dropdown',
    placeholder: 'All Descriptions',
    options: [],
    selected: [],
  },
  {
    label: 'Types',
    key: 'type',
    type: 'dropdown',
    placeholder: 'All types',
    options: ['Condition', 'Endorsement', 'Exclusion', 'Warranty', 'Wording'],
    selected: [],
  },
  {
    label: 'Tags',
    key: 'tags',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
  {
    label: 'Show archived',
    key: 'deleted_at',
    type: 'checkbox',
    isChecked: false,
    onChange: () => {},
  },
];

const initialColumns: Column[] = [
  {
    label: 'Description',
    key: 'description',
    sortable: true,
    normalizer: (fieldValue = '') => fieldValue.toLowerCase(),
    headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    dataCellClassName: 'truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
  },
  {
    label: 'Type',
    key: 'type',
    sortable: true,
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Tags',
    key: 'tags',
    dataCellClassName: '',
    render: (item: any, tags: Tag[], setIsLoading) => <ItemTags item={item} tags={tags} setIsLoading={setIsLoading} />,
  },
  {
    label: 'Edit',
    key: 'edit',
    type: 'link',
    href: '#',
  },
];

const getDescriptionsFilterOptions = (data: any[], showArchivedClauses: boolean) => {
  const descriptionsFilterOptions =
    data?.reduce((prev: string[], curr: ClauseShort) => {
      if (prev.indexOf(curr.description) === -1) {
        if (showArchivedClauses || !curr.deleted_at) {
          prev.push(curr.description);
        }
      }
      return prev;
    }, []) ?? [];
  descriptionsFilterOptions.sort();

  return descriptionsFilterOptions;
};

export const Clauses: React.FC = () => {
  const { showModal } = useModal();
  const [columns, setColumns] = useState(initialColumns);
  const [showArchivedClauses, setShowArchivedClauses] = useState<boolean>(false);
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection, setShouldFetchData } = useDataQuery(
    'clauses',
    initialFilters,
    fetchClauseLibrary,
    (data) => {
      const descriptionsFilterOptions = getDescriptionsFilterOptions(data, showArchivedClauses);
      const clonedFilters = [...filters];
      (
        clonedFilters[clonedFilters.findIndex((filter: Filter) => filter.key === 'description')] as DropdownFilter
      ).options = descriptionsFilterOptions;
      setFilters([...clonedFilters]);
    }
  );

  const tagsLibraryData = useTags();

  const handleEditClauseClick = (clause: ClauseShort) => () =>
    showModal(MODALS.ADMIN_CLAUSE, { clause, setShouldFetchData, isAdd: false, tagsLibraryData });

  useEffect(() => {
    const clonedFilters = [...filters];
    (clonedFilters[clonedFilters.findIndex((filter: Filter) => filter.key === 'tags')] as DropdownFilter).options =
      tagsLibraryData.map((tag: Tag) => tag.label);
    const showArchivedFilter = clonedFilters[
      clonedFilters.findIndex((filter: Filter) => filter.key === 'deleted_at')
    ] as CheckboxFilter;
    showArchivedFilter.onChange = () => setShowArchivedClauses(!showArchivedClauses);
    showArchivedFilter.isChecked = showArchivedClauses;
    setFilters(clonedFilters);

    const clonedColumns = [...columns];
    clonedColumns[clonedColumns.findIndex((column: Column) => column.key === 'edit')].onClick = handleEditClauseClick;
    setColumns(clonedColumns);
  }, [tagsLibraryData]);

  useEffect(() => {
    const clonedFilters = [...filters];
    const showArchivedFilter = clonedFilters[
      clonedFilters.findIndex((filter: Filter) => filter.key === 'deleted_at')
    ] as CheckboxFilter;
    showArchivedFilter.onChange = () => setShowArchivedClauses(!showArchivedClauses);
    showArchivedFilter.isChecked = showArchivedClauses;

    const descriptionsFilterOptions = getDescriptionsFilterOptions(data, showArchivedClauses);
    (
      clonedFilters[clonedFilters.findIndex((filter: Filter) => filter.key === 'description')] as DropdownFilter
    ).options = descriptionsFilterOptions;

    setFilters(clonedFilters);
  }, [showArchivedClauses]);

  const actions: Action[] = [
    {
      key: 'add_clause',
      label: 'New Clause',
      onClick: () => {
        showModal(MODALS.ADMIN_CLAUSE, { setShouldFetchData, isAdd: true, tagsLibraryData });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data}
      status={status}
      tags={tagsLibraryData}
    />
  );
};

export const AdminClauses = withPrivateRoute({ adminOnly: true })(Clauses);
