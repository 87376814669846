type IsNotEmptyArray = {
  errors: boolean;
  type: string;
  validate: (_schema: unknown, data: Array<string> | undefined) => boolean;
};

export const isNotEmptyArray: IsNotEmptyArray = {
  errors: true,
  type: 'array',
  validate: function validateIsNotEmptyArray(_schema, data) {
    // @ts-ignore
    validateIsNotEmptyArray.errors = [];
    // @ts-ignore
    validateIsNotEmptyArray.errors.push({
      keyword: 'isNotEmptyArray',
      message: 'Field is required',
      params: {
        keyword: 'isNotEmptyArray',
      },
    });
    return !!data && data?.length > 0;
  },
};
