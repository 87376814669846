import clsx from 'clsx';

import { Spinner } from './Spinner';

export interface LoadingOverlayProps {
  active?: boolean;
  onClick?: any;
  size?: 'small' | 'normal' | 'large';
}

const LoadingOverlay = ({ active, size, ...props }: LoadingOverlayProps) => {
  console.log('LoadingOverlay', active, size);
  return active ? (
    <div
      onClick={props.onClick}
      className={clsx(
        'absolute',
        'top-0',
        'bottom-0',
        'left-0',
        'right-0',
        'z-50',
        'h-full',
        'w-full',
        'bg-gray-700',
        'opacity-40',
        'transition',
        'duration-200',
        'ease-in',
        'text-clip',
        'select-none',
        'flex',
        'items-center',
        'justify-center'
      )}
    >
      <div>
        <Spinner color="white" size={size} />
      </div>
    </div>
  ) : null;
};
export default LoadingOverlay;
