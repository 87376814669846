import React, { useContext, useEffect, useState } from 'react';

import qs from 'qs';
import { useQuery } from 'react-query';

import type { ClauseShort, FilterSearchType } from '../../../../@types/types';
import type { UIInputValue } from '../../../../@types/types';
import type { Tag } from '../../../../@types/types';
import Button from '../../../components/Button';
import InputFilterSearch from '../../../components/InputFilterSearch';
import { TAGS } from '../../../constants';
import { fetchTags } from '../../../queries';
import VerticalTabsContext from '../../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from '../ClauseLibraryContext';
import { useClause } from '../useClause';

export const SelectOrCreate = () => {
  const { clauseLibrary, setIsAddClause, handleCloseModal } = useContext(ClauseLibraryContext);
  const { entityClausesState, isTemplate } = useContext(VerticalTabsContext);
  const { persistClauses } = useClause();
  const [selectedTag, setSelectedTag] = useState<UIInputValue | never>();
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { data: tagsData } = useQuery('tags', () => fetchTags(queryParams.authToken as string), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setFilteredTags(data);
    },
  });

  useEffect(() => {
    if (selectedTag) {
      setFilteredTags(tagsData ?? [].filter((tag: Tag) => tag.id !== (selectedTag as unknown as Tag).id));
    }
  }, [selectedTag]);

  const handleAddClauseFromLibraryToSubmission = async (selectedClause: UIInputValue) => {
    // @ts-ignore
    const selectedClauseId = selectedClause?.id;
    const { description, type, content } = clauseLibrary.find((i: ClauseShort) => i.id === selectedClauseId)!;
    try {
      const newClause = {
        description,
        type,
        content,
        id: selectedClauseId,
        is_edited: false,
        index: entityClausesState.length,
        created_at: new Date().toISOString(),
      };
      const newClauses = entityClausesState ? [...entityClausesState, newClause] : [newClause];
      persistClauses(newClauses, newClause);
      handleCloseModal();
    } catch (e) {
      console.log(e);
    }
  };
  const handleCreateNewClauseClick = () => setIsAddClause(true);

  const handleSelectTag = (tag: UIInputValue) => {
    setSelectedTag(tag);
  };

  const handleSelectClause = (clause: UIInputValue) => {
    handleAddClauseFromLibraryToSubmission(clause);
  };

  const filterSearch: FilterSearchType = {
    filterDropdown: {
      options:
        (selectedTag as unknown as Tag)?.id === TAGS.ALL_TAGS_ID || !selectedTag
          ? filteredTags
              .sort((a, b) => (a.label > b.label ? 1 : -1))
              .map((tag: Tag) => ({
                name: tag.label,
                value: { id: tag.id, label: tag.label },
              }))
          : [
              TAGS.ALL_TAGS_OPTION,
              ...filteredTags
                .sort((a, b) => (a.label > b.label ? 1 : -1))
                .map((tag: Tag) => ({
                  name: tag.label,
                  value: { id: tag.id, label: tag.label },
                })),
            ],
      onChange: handleSelectTag,
      value: selectedTag,
      formatter: (value) => value?.label,
      placeholder: TAGS.ALL_TAGS_LABEL,
    },
    searchDropdown: {
      options: clauseLibrary.reduce(
        (filteredClauses: { name: string; value: { [x: string]: string } }[], currentClause) => {
          const { description, id, tags = [] } = currentClause;
          if (entityClausesState?.find((i: ClauseShort) => i.description === description)) {
            return filteredClauses;
          }
          const selectedTagId = (selectedTag as unknown as Tag)?.id;
          if (selectedTag && selectedTagId !== TAGS.ALL_TAGS_ID && !tags.includes(selectedTagId)) {
            return filteredClauses;
          }
          filteredClauses.push({ name: description, value: { id, description } });
          return filteredClauses;
        },
        []
      ),
      onChange: handleSelectClause,
      formatter: (value) => value?.description,
      placeholder: 'Select from clause library',
    },
  };

  return (
    <>
      <InputFilterSearch {...filterSearch} />
      <div className="flex flex-col-reverse sm:flex-row mt-5 sm:mt-4 justify-end gap-2 sm:gap-4">
        <Button iconName={'hand-empty'}>
          <a href="mailto:documentproduction@edbroking.com?subject=Clause%20Request:%20[Name]%20-%20(Please%20Attach%20Clause%20Wording)">
            Request a Clause
          </a>
        </Button>
        {!isTemplate && (
          <Button onClick={handleCreateNewClauseClick} kind="primary">
            Create a new Clause
          </Button>
        )}
      </div>
    </>
  );
};
