import React, { useState } from 'react';

import qs from 'qs';
import { useQuery } from 'react-query';

import InputCalendar from '../../components/InputCalendar';
import InputText from '../../components/InputText';
import { fetchSubmissions, fetchTags, fetchTemplates } from '../../queries';
import { InnerContent } from './components/InnerContent';

interface TemplatesLandingPageProps {
  id: string;
  details: { umr: string; inception_date: string; insured_name: string; expiry_date: string };
  documentType: string;
}

export const TemplatesLandingPage: React.FC<TemplatesLandingPageProps> = ({ id, details, documentType }) => {
  const { expiry_date, inception_date, insured_name } = details;
  const [umr, setUmr] = useState(details.umr);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, status } = useQuery('templates', () => fetchTemplates(queryParams.authToken as string), {
    refetchOnWindowFocus: false,
  });

  const { data: tagsData, status: tagsStatus } = useQuery('tags', () => fetchTags(queryParams.authToken as string), {
    refetchOnWindowFocus: false,
  });

  const { data: submissionsData, status: submissionsStatus } = useQuery(
    'submissions',
    () => fetchSubmissions(queryParams.authToken as string),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (status === 'loading' || tagsStatus === 'loading' || submissionsStatus === 'loading') {
    return (
      <div className=" loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  const handleUmrChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => setUmr(target.value);

  return (
    <div className="flex-1 relative z-0 flex overflow-hidden bg-gray-50">
      <aside className="relative xl:flex xl:flex-col flex-shrink-0 w-1/3 lg:w-1/4 border-l border-gray-200 overflow-y-auto wysiwyg-manager-side-panel bg-white">
        <div className="text-xs py-4 font-medium px-4 uppercase text-gray-500 tracking-wide border-b border-gray-200">
          Structured data points
        </div>
        <div className="relative p-4 mt-2 flex flex-col space-y-6">
          <InputText
            isDisabled
            labelText="UMR"
            name="umr"
            value={umr}
            onChange={handleUmrChange}
            placeholder="B0702AD302190P"
          />
          <InputText
            isDisabled
            labelText="Insured Name"
            name="insured_name"
            value={insured_name}
            placeholder="OLC (Barbados) Limited"
          />
          <InputCalendar
            isDisabled
            labelText="Inception Date"
            name="inception_date"
            value={inception_date}
            placeholder="27/Aug/2022"
          />
          <InputCalendar
            isDisabled
            labelText="Expiry Date"
            name="expiry_date"
            value={expiry_date}
            placeholder="27/Aug/2023"
          />
        </div>
      </aside>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none wysiwyg-manager-side-panel">
        <div className="inset-0 py-6 pt-0 h-full pb-0">
          <InnerContent
            id={id}
            templates={(data ?? []).filter(
              (i) => i.visibility_status === 'PUBLISHED' && i.document_type === `TEMPLATE_${documentType}`
            )}
            submissionUmr={umr}
            tags={tagsData ?? []}
            submissions={(submissionsData ?? []).filter(
              (i) => i.document_type === documentType && i.visibility_status === 'FINAL'
            )}
            documentType={documentType}
          />
        </div>
      </main>
    </div>
  );
};
