type IsNotEmpty = {
  errors: boolean;
  type: string;
  validate: (_schema: unknown, data: string | unknown) => boolean;
};

export const isNotEmpty: IsNotEmpty = {
  errors: true,
  type: 'string',
  validate: function validateIsNotEmpty(_schema, data) {
    // @ts-ignore
    validateIsNotEmpty.errors = [];
    // @ts-ignore
    validateIsNotEmpty.errors.push({
      keyword: 'isNotEmpty',
      message: 'Field is required',
      params: {
        keyword: 'isNotEmpty',
      },
    });
    return typeof data === 'string' && data.trim() !== '';
  },
};
