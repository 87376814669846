import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const createTag = async (data: Pick<Tag, 'label'>, authToken: string): Promise<Pick<Tag, 'id'>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/tags${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
