import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import moment from 'moment';

import type { S3Schema, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };

export const useBermudaDeclarationRelmEoCyberEffects = (
  _schema: S3Schema,
  formValues: UIFormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn
): void => {
  useEffect(() => {
    const binder_name = get(formValues, 'binder_name', '');
    const primary_placement_or_excess_placement = get(formValues, 'primary_placement_or_excess_placement', '');
    if (binder_name === 'Cyber Binder' && primary_placement_or_excess_placement === 'Primary Placement') {
      const nextFormValues = cloneDeep(getFormState());
      set(
        nextFormValues,
        'claims_made_liability_coverages.technology_professional_services_errors_and_omissions_liability.retroactive_date',
        'N/A'
      );
      setFormValues(nextFormValues);
    }
  }, [formValues.binder_name, formValues.primary_placement_or_excess_placement]);
  useEffect(() => {
    const currentBroker = get(formValues, 'wholesale_broker', '');
    if (currentBroker) {
      const nextFormValues = cloneDeep(getFormState());
      const isOtherBroker = currentBroker === 'Other';
      // @ts-ignore
      const brokerEmail = isOtherBroker ? '' : `${currentBroker?.replace(' ', '.')}@edbroking.com`;
      set(nextFormValues, 'wholesale_broker_email', brokerEmail);
      if (!isOtherBroker) {
        set(nextFormValues, 'wholesale_broker_other', null);
        set(nextFormValues, 'wholesale_broker_email_other', null);
      }
      setFormValues(nextFormValues);
    }
  }, [formValues.wholesale_broker]);
  useEffect(() => {
    const nextFormValues = cloneDeep(getFormState());
    if (formValues.inception_date && formValues.number_of_days_premium_to_be_paid_within) {
      set(
        nextFormValues,
        'settlement_due_date',
        moment(formValues?.inception_date)
          .add(formValues.number_of_days_premium_to_be_paid_within, 'days')
          .toISOString()
      );
    } else {
      set(nextFormValues, 'settlement_due_date', null);
    }
    setFormValues(nextFormValues);
  }, [formValues.number_of_days_premium_to_be_paid_within]);
  useEffect(() => {
    if (formValues.primary_placement_or_excess_placement !== 'Primary Placement') {
      return;
    }
    const nextFormValues = cloneDeep(getFormState());
    if (
      get(
        formValues,
        'claims_made_liability_coverages.technology_professional_services_errors_and_omissions_liability'
      ) === undefined
    ) {
      set(
        nextFormValues,
        'claims_made_liability_coverages.technology_professional_services_errors_and_omissions_liability.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(
        nextFormValues,
        'claims_made_liability_coverages.technology_professional_services_errors_and_omissions_liability.retention',
        100000
      );
    }
    if (get(formValues, 'claims_made_liability_coverages.security_event_liability') === undefined) {
      set(
        nextFormValues,
        'claims_made_liability_coverages.security_event_liability.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(nextFormValues, 'claims_made_liability_coverages.security_event_liability.retention', 100000);
    }
    if (get(formValues, 'claims_made_liability_coverages.security_event_liability') === undefined) {
      set(
        nextFormValues,
        'claims_made_liability_coverages.privacy_liability_regulatory.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(nextFormValues, 'claims_made_liability_coverages.privacy_liability_regulatory.retention', 100000);
    }
    if (
      get(
        formValues,
        'first_party_coverages_business_interruption_coverages.system_interruption_business_interruption'
      ) === undefined
    ) {
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.system_interruption_business_interruption.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.system_interruption_business_interruption.waiting_period',
        72
      );
    }
    if (get(formValues, 'first_party_coverages_business_interruption_coverages.data_recovery_expense') === undefined) {
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.data_recovery_expense.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.data_recovery_expense.waiting_period',
        72
      );
    }
    if (
      get(
        formValues,
        'first_party_coverages_business_interruption_coverages.service_interruption_business_interruption'
      ) === undefined
    ) {
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.service_interruption_business_interruption.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(
        nextFormValues,
        'first_party_coverages_business_interruption_coverages.service_interruption_business_interruption.waiting_period',
        72
      );
    }
    if (
      get(
        formValues,
        'crisis_management_expense_and_computer_extortion_coverages.crisis_management_expense_and_related_coverages'
      ) === undefined
    ) {
      set(
        nextFormValues,
        'crisis_management_expense_and_computer_extortion_coverages.crisis_management_expense_and_related_coverages.coverage_section_limit_of_insurance_per_claim_event',
        1000000
      );
      set(
        nextFormValues,
        'crisis_management_expense_and_computer_extortion_coverages.crisis_management_expense_and_related_coverages.retention',
        100000
      );
    }
    if (get(formValues, 'crisis_management_expense_and_computer_extortion_coverages.cyber_extortion') === undefined) {
      set(
        nextFormValues,
        'crisis_management_expense_and_computer_extortion_coverages.cyber_extortion.coverage_section_limit_of_insurance_per_claim_event',
        500000
      );
      set(
        nextFormValues,
        'crisis_management_expense_and_computer_extortion_coverages.cyber_extortion.retention',
        100000
      );
    }
    if (get(formValues, 'social_engineering_coverage_social_engineering') === undefined) {
      set(
        nextFormValues,
        'social_engineering_coverage_social_engineering.coverage_section_limit_of_insurance_per_claim_event',
        100000
      );
      set(nextFormValues, 'social_engineering_coverage_social_engineering.retention', 100000);
    }
    setFormValues(nextFormValues);
  }, [formValues.primary_placement_or_excess_placement]);
};
