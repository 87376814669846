import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase, UIComponentSize, UIComponentSizes } from '../../@types/types';
import DescriptionText from './DescriptionText';
import ErrorText from './ErrorText';
import Icon from './Icon';
import Label from './Label';
import type { SvgIconName } from './Svg';

const sizes: UIComponentSizes = {
  normal: 'h-10',
  small: 'h-10',
};

export interface InputFieldProps extends UIComponentBase {
  descriptionText?: string | React.ReactNode;
  errors?: string | string[];
  icon?: React.ReactNode;
  iconName?: SvgIconName;
  iconPosition?: 'left' | 'right';
  id?: string;
  isRequired?: boolean;
  labelText?: React.ReactNode;
  link?: React.ReactNode;
  noErrorLabel?: boolean;
  size?: UIComponentSize;
  name?: string;
  isDisabled?: boolean; //TODO: not implemented, but other components use this prop
}

const InputField: React.FC<InputFieldProps> = ({
  children,
  className,
  descriptionText,
  errors,
  icon,
  iconName,
  iconPosition = 'right',
  id,
  isRequired,
  labelText,
  link,
  name,
  noErrorLabel,
  size = 'normal',
}) => {
  const errorsArray = Array.isArray(errors) ? errors : [errors];

  return (
    <div
      data-error={errorsArray?.length > 0}
      className={clsx('relative', !clsx(className).match(/w-/) && 'w-full', className, errors && 'border-red-300')}
    >
      {labelText && (
        <div className="mb-2 font-medium text-sm text-gray-700 flex justify-between">
          <Label htmlFor={id || name} isRequired={isRequired}>
            {labelText}
          </Label>
          {link}
        </div>
      )}

      <div className="relative">
        {icon}

        {iconName && (
          <div
            className={clsx(
              'flex w-10 z-10 p-3 items-center justify-center absolute pointer-events-none',
              iconPosition === 'right' && 'right-0',
              iconPosition === 'left' && 'left-0',
              sizes[size]
            )}
          >
            <Icon name={iconName} className="w-3 fill-current text-gray-800" />
          </div>
        )}

        {children}
      </div>

      {descriptionText && !errors && <DescriptionText id={`${name}Description`}>{descriptionText}</DescriptionText>}

      {!noErrorLabel && errors && errorsArray.map((errorText) => <ErrorText key={errorText}>{errorText}</ErrorText>)}
    </div>
  );
};

export default InputField;
