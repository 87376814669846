type IsWithDisclaimerWarning = {
  errors: boolean;
  type: string;
  validate: (_schema: unknown, data: string | unknown) => boolean;
};

export const isWithDisclaimerWarning: IsWithDisclaimerWarning = {
  errors: true,
  type: 'string',
  validate: function validateIsWithDisclaimerWarning(_schema, data) {
    // @ts-ignore
    validateIsWithDisclaimerWarning.errors = [];
    // @ts-ignore
    validateIsWithDisclaimerWarning.errors.push({
      keyword: 'isWithDisclaimerWarning',
      message: 'WARNING! Get the Client Disclaimer Letter signed and escalate this before you proceed further!',
      params: {
        keyword: 'isWithDisclaimerWarning',
      },
    });
    return typeof data === 'string' && data.trim() !== 'No';
  },
};
