import clsx from 'clsx';

const sizes = {
  xs: 'w-5 h-5',
  small: 'w-14 h-14',
  normal: 'w-24 h-24',
  large: 'h-32 w-32',
};

interface SpinnerProps {
  color?: 'white' | 'black';
  size?: 'xs' | 'small' | 'normal' | 'large';
  className?: string;
}

const colors = {
  white: 'border-gray-50',
  black: 'border-gray-900',
};

export const Spinner = ({ size = 'large', color = 'black', className }: SpinnerProps) => (
  <div className={clsx('loader flex justify-center items-center', className)}>
    <div className={clsx('animate-spin rounded-full border-b-2 ', colors[color], sizes[size])} />
  </div>
);
