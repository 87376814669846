import React from 'react';

import clsx from 'clsx';

import type { Dropdown } from '../../@types/types';
import InputSearch from './InputSearch';
import InputSelect from './InputSelect';

const InputFilterSearch: React.FC<{ filterDropdown: Dropdown; searchDropdown: Dropdown }> = ({
  filterDropdown,
  searchDropdown,
}) => (
  <div className="flex">
    <InputSelect
      className={clsx('w-1/3', filterDropdown?.className)}
      buttonClassName={clsx(
        'truncate rounded-r-none text-gray-900 bg-gray-100 border-r-0 border-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600',
        filterDropdown?.buttonClassName
      )}
      valueClassName="truncate"
      onChange={filterDropdown?.onChange}
      options={filterDropdown.options}
      placeholder={filterDropdown.placeholder}
      formatter={filterDropdown.formatter}
      value={filterDropdown?.value}
    />
    <InputSearch
      className={clsx('w-2/3', searchDropdown?.className)}
      buttonClassName={clsx('rounded-l-none', searchDropdown?.buttonClassName)}
      onChange={searchDropdown?.onChange}
      options={searchDropdown.options}
      placeholder={searchDropdown.placeholder}
      formatter={searchDropdown.formatter}
      value={searchDropdown?.value}
    />
  </div>
);

export default InputFilterSearch;
