import React, { Fragment, useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import get from 'lodash.get';
import type { QueryStatus } from 'react-query';

import type {
  Action,
  CheckboxFilter,
  Column,
  DropdownFilter,
  Filter,
  SetIsLoading,
  SortOrder,
  Tag,
  ToggleSortOrderDirectionFn,
} from '../../../../@types/types';
import InputMultiSelect from '../../../components/InputMultiSelect';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Pagination from '../../../components/Pagination';
import Toggle from '../../../components/Toggle';
import { sortData } from '../../../helpers/sortData';

const getFilteredData = (data: any[], filters: Filter[], tags: Tag[]) => {
  let filteredData = data;
  filters.forEach((filter) => {
    filteredData = filteredData?.filter((item: any) => {
      if (filter.type === 'checkbox') {
        return filter.isChecked ? true : !get(item, filter.key, false);
      } else {
        return filter.selected.length > 0
          ? filter.key === 'tags'
            ? filter.selected.some((element) =>
                get(item, filter.key, [])
                  .map((tagId: string) => tags.find((tag: Tag) => tag.id === tagId)?.label)
                  .includes(element)
              )
            : filter.selected.includes(get(item, filter.key, ''))
          : true;
      }
    });
  });
  return filteredData;
};

const getItemValue = (column: Column, item: any) => {
  const defaultItemValue = get(item, column.key);
  if (defaultItemValue || !column?.fallbackKeys?.length) {
    return typeof column.formatter === 'function' ? column.formatter(defaultItemValue) : defaultItemValue;
  }
  for (let i = 0; i < column.fallbackKeys.length; i++) {
    const fallbackItemValue = get(item, column.fallbackKeys[i]);
    if (fallbackItemValue) {
      return typeof column.formatter === 'function' ? column.formatter(fallbackItemValue) : fallbackItemValue;
    }
  }
};

const TableDataCell = ({
  column,
  item,
  tags,
  setIsLoading,
}: {
  column: Column;
  item: any;
  tags: Tag[];
  setIsLoading?: SetIsLoading;
}) => {
  return column.type === 'link' ? (
    <td
      key={column.key}
      className={
        column.dataCellClassName ?? 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
      }
    >
      {typeof column?.onClick === 'function' ? (
        <a
          href="#"
          onClick={column.onClick(item)}
          className={column.linkClassName ?? 'text-indigo-600 hover:text-indigo-900'}
        >
          {column.label}
        </a>
      ) : (
        <a
          target={column.target ?? ''}
          href={column?.href?.indexOf('{id}') === -1 ? column?.href ?? '#' : column?.href?.replace('{id}', item.id)}
          rel={column.rel ?? ''}
          className={column.linkClassName ?? 'text-indigo-600 hover:text-indigo-900'}
        >
          {column.label}
        </a>
      )}
    </td>
  ) : (
    <Fragment key={column.key}>
      {column.render ? (
        column.render(item, tags, setIsLoading)
      ) : (
        <td
          key={column.key}
          className={column.dataCellClassName ?? 'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}
        >
          {getItemValue(column, item)}
        </td>
      )}
    </Fragment>
  );
};

export const Table = ({
  data,
  status,
  actions = [],
  filters,
  setFilters,
  columns,
  sortOrder,
  toggleSortOrderDirection,
  pageSize = 12,
  tags = [],
}: {
  data: any[];
  status?: QueryStatus;
  actions?: Action[];
  filters: Filter[];
  setFilters: (arg0: Filter[]) => void;
  columns: Column[];
  sortOrder: SortOrder;
  toggleSortOrderDirection: ToggleSortOrderDirectionFn;
  pageSize?: number;
  tags?: Tag[];
}) => {
  const [filteredData, setFilteredData] = useState(data ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (data) {
      setFilteredData(getFilteredData(data, filters, tags));
    }
  }, [filters, data]);

  useEffect(() => {
    const clonedFilteredData = [...filteredData];
    sortData(clonedFilteredData, sortOrder);
    setFilteredData(clonedFilteredData);
  }, [sortOrder]);

  if (!['error', 'success'].includes(status ?? '')) {
    return (
      <div className=" loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <LoadingOverlay active={isLoading} />
      <div className="lg:flex lg:items-center">
        <div className="lg:flex-auto sm:mb-4 lg:mb-0">
          {actions.map((action) => (
            <div key={action.key} className="md:pl-0">
              <button
                type="button"
                className="xl:mt-6 inline-flex items-end justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-md font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={action.onClick}
              >
                {action.label}
              </button>
            </div>
          ))}
        </div>
        <div className="md:flex-none xl:flex mt-4 md:mt-0 xl:ml-16">
          {filters.map((filter, index) =>
            (filter as CheckboxFilter).type === 'checkbox' ? (
              <div key={filter.key} className={filter?.wrapperClassName ?? ''}>
                <Toggle
                  value={(filter as CheckboxFilter).isChecked ? 1 : 0}
                  className={filter?.className ?? 'ml-2 xl:ml-4 mr-4 mt-9 mb-0'}
                  labelText={filter.label}
                  onClick={(filter as CheckboxFilter).onChange}
                />
              </div>
            ) : (
              <div key={filter.key}>
                <InputMultiSelect
                  labelText={filter.label}
                  name={filter.key}
                  placeholder={(filter as DropdownFilter).placeholder}
                  className={
                    filter.className ??
                    'mr-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md search-input'
                  }
                  options={(filter as DropdownFilter).options}
                  onChange={(value) => {
                    if (value) {
                      const clonedFilters = [...filters];
                      (clonedFilters[index] as DropdownFilter).selected = value;
                      setFilters(clonedFilters);
                    }
                  }}
                  onSelect={() => {}}
                />
              </div>
            )
          )}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column: Column) => {
                      if (column.sortable) {
                        return (
                          <th
                            key={column.key}
                            scope="col"
                            className={
                              column?.headingCellClassName ??
                              'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                            }
                          >
                            <a
                              href="#"
                              className="group inline-flex"
                              onClick={toggleSortOrderDirection(column.key, column.normalizer)}
                            >
                              {column.label}
                              <span
                                className={clsx(
                                  sortOrder.fieldName === column.key
                                    ? 'ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300'
                                    : 'ml-2 flex-none rounded bg-gray-200 text-gray-400 invisible group-hover:visible group-focus:visible'
                                )}
                              >
                                {sortOrder.fieldName === column.key && sortOrder.sortDirection === 'desc' && (
                                  <ChevronDownIcon className="h-5 w-5" />
                                )}
                                {sortOrder.fieldName === column.key && sortOrder.sortDirection === 'asc' && (
                                  <ChevronUpIcon className="h-5 w-5" />
                                )}
                              </span>
                            </a>
                          </th>
                        );
                      }
                      if (column.type === 'link') {
                        return (
                          <th
                            key={column.key}
                            scope="col"
                            className={column?.headingCellClassName ?? 'relative py-3.5 pl-3 pr-4 sm:pr-6'}
                          >
                            <span className="sr-only">{column.label}</span>
                          </th>
                        );
                      }
                      return (
                        <th
                          key={column.key}
                          scope="col"
                          className={
                            column?.headingCellClassName ?? 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          }
                        >
                          {column.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredData.slice(page * pageSize, (page + 1) * pageSize).map((item) => (
                    <tr key={item.id}>
                      {columns.map((column) => (
                        <TableDataCell
                          key={`${item.id}-${column.key}`}
                          column={column}
                          item={item}
                          tags={tags}
                          setIsLoading={setIsLoading}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination totalResults={filteredData?.length ?? 0} pageSize={pageSize} onPageChange={setPage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
