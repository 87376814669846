import Ajv from 'ajv';

import { isNotEmpty, isNotEmptyArray, isWithDisclaimerWarning } from '../keywords';

const ajv = new Ajv({ allErrors: true, verbose: true, coerceTypes: true, useDefaults: true });

ajv.addKeyword('isNotEmpty', isNotEmpty);
ajv.addKeyword('isNotEmptyArray', isNotEmptyArray);
ajv.addKeyword('isWithDisclaimerWarning', isWithDisclaimerWarning);

export default ajv;
