import type { Dispatch, SetStateAction } from 'react';
import React, { useState, useEffect } from 'react';

import { RadioGroup } from '@headlessui/react';
import qs from 'qs';

import type { UISelectionType } from '../../../../../@types/types';
import InputSelect from '../../../../components/InputSelect';
import Modal from '../../../../components/Modal';
import { createTemplate } from '../../../../mutations';

const templateTypes = [
  { name: 'MRC', description: '' },
  { name: 'Endorsement', description: '' },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const openTemplateInNewTab = (id: string) => {
  if (id) {
    const win = window.open(`/templates/${id}`, '_blank');
    win?.focus();
  }
};

const AdminCreateTemplateModal = ({
  existingTemplates,
  handleClose,
  setShouldFetchTemplates,
}: {
  existingTemplates: UISelectionType[];
  handleClose: () => void;
  setShouldFetchTemplates: Dispatch<SetStateAction<boolean>>;
}) => {
  const [templateType, setTemplateType] = useState(templateTypes[0]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  useEffect(() => {
    setFilteredTemplates(
      // @ts-ignore
      existingTemplates.filter((item) => item.type === `TEMPLATE_${templateType.name.toUpperCase()}`)
    );
  }, [templateType]);
  const handleCopyFromTemplate = async (copyFromTemplateId: any) => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { id } = await createTemplate(
        {
          copy_from_template: copyFromTemplateId,
        },
        queryParams.authToken as string
      );
      setShouldFetchTemplates(true);
      openTemplateInNewTab(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open className="w-full lg:w-1/2 sm:w-5/6" onClose={handleClose} title="Create template">
      <RadioGroup value={templateType} onChange={setTemplateType}>
        <RadioGroup.Label className="mb-2 font-medium text-sm text-gray-700 flex justify-between">
          Select Type
        </RadioGroup.Label>
        <div className="-space-y-px rounded-md bg-white mb-4">
          {templateTypes.map((type, idx) => (
            <RadioGroup.Option
              key={type.name}
              value={type}
              className={({ checked }) =>
                classNames(
                  idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  idx === templateTypes.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                    >
                      {type.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                    >
                      {type.description}
                    </RadioGroup.Description>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <InputSelect
        onChange={(value) => value && handleCopyFromTemplate(value)}
        labelText="Select from existing templates"
        options={filteredTemplates}
      />
    </Modal>
  );
};

export default AdminCreateTemplateModal;
