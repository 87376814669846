import axios from 'axios';

import type { Submission, ServerResponse } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchSubmission = async (id: string, authToken: string): Promise<ServerResponse<Submission>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/submissions-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
